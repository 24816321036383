import {
  FEATURE_FLAGS,
  RegionSelectionWallet,
} from '@highmark-web/highmark-common';
import { LeagueCombinedConfig } from '@leagueplatform/core';
import { firebaseRemoteConfig } from 'common/firebase/firebase';
import { getBoolean } from 'firebase/remote-config';

export function getWalletConfig(): LeagueCombinedConfig['wallet'] {
  return {
    showLandingPageHeaderBranding: true,
    showLandingPageClaimsEntry: import.meta.env.VITE_ENV === 'staging',
    enableNoNetworkLiveBalances: getBoolean(
      firebaseRemoteConfig,
      FEATURE_FLAGS.WALLET_ENABLE_NO_NETWORK_LIVE_BALANCES,
    ),
    slots: {
      Banner: RegionSelectionWallet,
    },
  };
}
